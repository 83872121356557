<template>
  <div>
    <b-row
      align-h="center"
      class="mt-3"
    >
      <b-col lg="8">
        <b-card header="Assign Member To Entity">
          <b-form @submit.prevent="assignMemberToEntity">
            <portal-users-search :username.sync="formData.user" />
            <b-form-group>
              <b-button
                type="submit"
                variant="primary"
              >
                Assign
              </b-button>
              <back />
            </b-form-group>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import PortalUsersSearch from '@/common/components/Permissions/PortalUsersSearch.vue'

import handleAlerts from '@/common/compositions/common/handleAlerts'
import Back from '@/common/components/common/Back.vue'

export default {
  name: 'AssignMemberToEntity',
  components: {
    PortalUsersSearch,
    Back,
  },
  setup() {
    const { successfulOperationAlert, showErrors } = handleAlerts()

    return {
      successfulOperationAlert,
      showErrors,
    }
  },
  data() {
    return {
      formData: {
        entity: this.$store.getters['mainEntity/getEntityId'],
      },
    }
  },
  methods: {
    assignMemberToEntity() {
      this.$portalUsers.post('v1/entity/member/add', this.formData).then(() => {
        this.successfulOperationAlert('Member is assigned to entity successfully')
        this.$router.push({ name: 'portal-user-list' })
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
  },
}
</script>
<style lang="scss">

</style>
